<template>
    <div class="col-6 col-8 col-md-6 col-xl-4 p-2">
        <div class="card">
            <div v-if="nigun.image">
                <a href="#" @click.prevent="showDetails">
                    <img class="img-fluid" :src="nigun.image" />
                </a>
            </div>
            <h3>
                <a href="#" @click.prevent="showDetails">
                    {{ nigun.id }}
                </a>
            </h3>

            <ul v-if="nigun.traditions.length">
                <li v-for="tradition in nigun.traditions" :key="tradition">
                    {{tradition}}
                </li>
            </ul>
            <div class="d-flex align-items-center">
                <template v-if="nigun.related_files.length">
                    <a href="#" @click.prevent="showDetails">
                        {{nigun.related_files.length}}  <i class="bi bi-file-earmark-text-fill"></i>
                    </a>
                </template>
                <audio
                    controls
                    ref="audio"
                    v-if="nigun.mp3_file"
                    :key="`audio-preview-${nigun.id}`"
                    @play="handleAudioPlay"
                >
                    <source :src="nigun.mp3_file" type="audio/mpeg" />
                    Your browser does not support the audio tag.
                </audio>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    name: "NigunPreview",
    props: {
        nigun: Object,
    },
    setup(props, ctx) {
        const audio = ref()

        function showDetails() {
            ctx.emit("selected");
        }

        function handleAudioPlay() {
            ctx.emit("audio:play")
        }

        function pauseAudio() {
            if (audio.value) {
                audio.value.pause()
            }
        }

        return {
            audio,
            handleAudioPlay,
            pauseAudio,
            showDetails,
        };
    },
};
</script>

<style scoped>
.btn,
.btn:hover {
    background-color: #c6a783 !important;
    color: #ffffff !important;
    text-transform: capitalize;
}
.card {
    background: transparent;
    border: none;
}
h3 a {
    color: #c6a783;
    text-decoration: none;
}
</style>
