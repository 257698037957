<template>
    <div>
        <div class="d-flex align-content-between flex-wrap bd-highlight">
            <template v-for="(nigun, idx) in hits" :key="nigun.id">
                <nigun-preview
                    ref="nigunPreviews"
                    :nigun="nigun"
                    @audio:play="handleAudioPlay(idx)"
                    @selected="handleSelect(idx)"
                ></nigun-preview>
            </template>
            <template v-if="!hits.length">
                <p class="text-capitalize">{{ notFoundTranslation }}</p>
            </template>
        </div>
        <content-modal v-if="showModal" @close="hideModal">
            <template #header>
                <div v-if="hits[currentNigunIndex].titles" class="nigun__title">
                    <h3>{{ hits[currentNigunIndex].titles[0] }}</h3>
                </div>
            </template>
            <template #body>
                <nigun-details
                    :nigun="hits[currentNigunIndex]"
                    :edit-url="getEditNigunUrl(hits[currentNigunIndex].id)"
                ></nigun-details>
            </template>
            <template #footer>
                <div>
                    <a
                        class="btn"
                        target="_blank"
                        title="Go to nign detail page"
                        :href="getNigunUrl(hits[currentNigunIndex].id)"
                    >
                        <i class="bi bi-box-arrow-up-right"></i>
                    </a>
                </div>
                <nav dir="ltr" class="d-flex align-items-center">
                    <span class="mx-2">{{ currentNigunIndex + 1}} / {{ hits.length}}</span>
                    <ul class="pagination">
                        <li
                            class="page-item"
                            :class="{'disabled': currentNigunIndex == 0}"
                        >
                            <button
                                class="page-link"
                                title="Previous nign"
                                type="button"
                                :disabled="currentNigunIndex == 0"
                                @click="handleSelect(currentNigunIndex - 1)"
                            >
                                <i class="bi bi-caret-left-fill"></i>
                            </button>
                        </li>
                        <li
                            class="page-item"
                            :class="{'disabled': currentNigunIndex + 1 >= hits.length}"
                        >
                            <button
                                class="page-link"
                                title="Next nign"
                                type="button"
                                :disabled="currentNigunIndex + 1 > hits.length"
                                @click="handleSelect(currentNigunIndex + 1)"
                            >
                                <i class="bi bi-caret-right-fill"></i>
                            </button>
                        </li>
                    </ul>
                </nav>
            </template>
        </content-modal>
    </div>
</template>

<script>
import { ref } from "vue";

import { parseObject } from "../utils/objects.js"

import NigunPreview from "./NigunPreview.vue";
import ContentModal from "../shared/components/ContentModal.vue";
import NigunDetails from "./NigunDetails.vue";

export default {
    name: "SearchResults",
    components: {
        NigunPreview,
        ContentModal,
        NigunDetails,
    },
    setup() {
        const nigunPreviews = ref(null);
        const activeAudioIndex = ref(null)

        const hits = parseObject("HITS");
        const { NIGN_VIEW_URL, NIGN_EDIT_URL } = parseObject("SEARCH_RESULTS_DATA")
        const TRANSLATIONS_DATA = parseObject("TRANSLATIONS_DATA")

        const currentNigunIndex = ref(null);
        const showModal = ref(false);
        function handleSelect(index) {
            if (index >= hits.length) {
                index = index - hits.length;
            } else if (index < 0) {
                index = hits.length + index;
            }
            currentNigunIndex.value = index;
            showModal.value = true;
        }

        function hideModal() {
            currentNigunIndex.value = null;
            showModal.value = false;
        }

        function getNigunUrl(nigunId) {
            return NIGN_VIEW_URL.replace(0, nigunId)
        }

        function getEditNigunUrl(nigunId) {
            return NIGN_EDIT_URL && NIGN_EDIT_URL.replace(0, nigunId)
        }

        function handleAudioPlay( nigunIndex ) {
            if (activeAudioIndex.value !== null && activeAudioIndex.value != nigunIndex) {
                nigunPreviews.value[activeAudioIndex.value].pauseAudio()
            }

            activeAudioIndex.value = nigunIndex
        }

        return {
            currentNigunIndex,
            getEditNigunUrl,
            getNigunUrl,
            handleAudioPlay,
            handleSelect,
            hideModal,
            hits,
            nigunPreviews,
            notFoundTranslation: TRANSLATIONS_DATA.NOT_FOUND_TRANS,
            showModal,
        };
    },
};
</script>

<style scoped>
.pagination {
    margin: 0;
}
</style>
